import { graphql } from "gatsby";
import React from "react";
import Seo from "../components/seo";
import Layout from "../components/layout";
import TopImage from "../components/topImage";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

interface GalleryProps {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        media: string[]; // Adjusted to be an array of strings
      };
      featuredImg: {
        childImageSharp: {
          gatsbyImageData: any;
        };
      };
    };
  };
}

export default function Gallery({ data }: GalleryProps) {
  const file = data.markdownRemark;
  const media = file.frontmatter.media;
  const title = file.frontmatter.title;
  const topImage = getImage(file.featuredImg.childImageSharp.gatsbyImageData);

  return (
    <SimpleReactLightbox>
      <Layout>
        <Seo title="Gallery" />
        <TopImage topImage={topImage} title={title} />
        <div className="container mx-auto w-9/12 pt-10 pb-10">
          <SRLWrapper>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {media.map((imageUrl, index) => (
                <a key={index} href={imageUrl}>
                  <img
                    src={imageUrl}
                    alt={`Image ${index}`}
                    className="transition ease-in-out delay-150 hover:scale-110 duration-500 rounded-xl w-full h-full object-cover"
                    loading="lazy"
                  />
                </a>
              ))}
            </div>
          </SRLWrapper>
        </div>
      </Layout>
    </SimpleReactLightbox>
  );
}

export const pageQuery = graphql`
  query Images($slug: String) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      id
      frontmatter {
        title
        media
      }
      featuredImg {
        childImageSharp {
          gatsbyImageData(
            transformOptions: { fit: COVER, cropFocus: CENTER }
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;
